define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/external-link", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="external-link">
    <a
      target="_blank"
      href={{@link}}
      title={{i18n "prom_alert_receiver.actions.alertmanager"}}
      rel="noopener noreferrer"
    >
      {{d-icon "far-list-alt"}}
    </a>
  </div>
  */
  {
    "id": "GhBFTmq1",
    "block": "[[[10,0],[14,0,\"external-link\"],[12],[1,\"\\n  \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[30,1]],[15,\"title\",[28,[37,0],[\"prom_alert_receiver.actions.alertmanager\"],null]],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"far-list-alt\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@link\"],false,[\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/external-link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "external-link"));
});